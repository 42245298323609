import { useEffect, useMemo } from 'react';
import { Form, Modal, notification, Select, Spin } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import styled from 'styled-components';

import { getAllStores } from '@/services/store.service';
import { changeUserStore } from '@/services/mobile-user.service';
import { useAudit } from '@/hooks';
import { normalizeSearchString } from '@/utils';

type FormData = {
  storeId: string;
};

/** The modal open state can be controlled in two ways:
 * 1. By not providing the `open` prop, the modal will be open if the `userId`
 *    is defined
 * 2. By providing the `open` prop (which requires the `userId` prop to be
 *    defined), the modal will be open if the `open` prop is true
 */
export const ChangeStoreModal = ({
  open,
  userId,
  storeId,
  onChangeStore,
  onCancel,
}: (
  | { open?: never; userId: string | undefined }
  | { open: boolean; userId: string }
) & {
  storeId: string | undefined;
  onChangeStore: () => void;
  onCancel: () => void;
}) => {
  const isModalOpen = useMemo(
    () => open ?? userId !== undefined,
    [open, userId],
  );

  const { data: stores, isLoading } = useQuery({
    queryKey: ['get-all-stores'],
    queryFn: getAllStores,
    enabled: isModalOpen,
    // Only let the query refresh after 30 seconds
    staleTime: 1000 * 30,
  });

  const data = useMemo(() => {
    if (!stores) return null;

    const currentStore = stores.find((store) => store.id === storeId);
    const otherStores = stores.filter((store) => store.id !== storeId);

    return { currentStore, otherStores };
  }, [storeId, stores]);

  const [form] = Form.useForm<FormData>();
  const audit = useAudit({ resourceName: 'mobile-user' });

  const { mutate: handleChangeStore, isPending: isChangingStore } = useMutation(
    {
      mutationFn: async (values: FormData) => {
        if (!userId) return;

        let result;
        try {
          result = await changeUserStore(userId, values.storeId);
        } catch (error) {}

        if (!result) {
          notification.error({ message: 'Failed to update user store' });
          return;
        }

        audit.onUpdate({
          target: 'change-store',
          data: {
            userId,
            newStoreId: values.storeId,
          },
        });
        notification.success({ message: 'User store updated successfully' });

        onChangeStore();
      },
    },
  );

  // Clear form on close
  useEffect(() => {
    if (!isModalOpen) {
      form.resetFields();
    }
  }, [form, isModalOpen]);

  return (
    <Modal
      title="Change User Store"
      open={isModalOpen}
      okButtonProps={{ disabled: isChangingStore }}
      destroyOnClose
      onOk={() => form.submit()}
      onCancel={onCancel}
    >
      {isLoading ? (
        <StyledSpin />
      ) : !data ? (
        <p>Could not load stores. Please try again later.</p>
      ) : (
        <Form form={form} layout="vertical" onFinish={handleChangeStore}>
          {data.currentStore ? (
            <p>
              Current Store:{' '}
              {data.currentStore.name.trim() ? (
                data.currentStore.name.trim()
              ) : (
                <span style={{ color: '#999' }}>
                  ID: {data.currentStore.id} (name not set)
                </span>
              )}
            </p>
          ) : (
            <p>
              This user does not have a valid store associated with their
              account
            </p>
          )}

          <Form.Item<FormData>
            name="storeId"
            label="Select the store where the user will be moved:"
            rules={[{ required: true, message: 'Please select a store' }]}
          >
            <Select
              placeholder="Select a store"
              showSearch
              options={data.otherStores.map((store) => ({
                value: store.id,
                label: store.name.trim() || `ID: ${store.id} (name not set)`,
                hasName: Boolean(store.name.trim()),
              }))}
              optionRender={(option) => {
                return option.data.hasName ? (
                  option.label
                ) : (
                  <span style={{ color: '#999' }}>{option.label}</span>
                );
              }}
              filterOption={(input, option) => {
                return normalizeSearchString(option?.label ?? '')
                  .toLowerCase()
                  .includes(normalizeSearchString(input).toLowerCase());
              }}
            />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

const StyledSpin = styled(Spin)`
  display: block;
  margin: 1rem auto;
`;

import { httpPut, httpGet, httpPost, handleError } from './util/axios';
import { Store } from '@/types';

export const getStoreDetails = async (
  storeId: string,
): Promise<{ store: Store }> => {
  return httpGet('store/read', `admin/${storeId}/details`);
};

export const getStoreFormData = async (storeId: string) => {
  return httpGet('store/read', `admin/${storeId}/form-data`);
};

export const updateStore = async (storeId: string, data: any) => {
  return httpPut('store/write', `admin/${storeId}/update`, data);
};

export const createDraftStore = async () => {
  return httpPost('store/write', `admin/create-draft`, {});
};

export const syncStoresBrandsForRange = async (
  storeId: string,
  brandId: string,
  startDate: Date | null,
  endDate: Date | null,
) => {
  try {
    return httpPost('store/write', `admin/update-store-brands-date-range`, {
      storeId,
      brandId,
      startDate,
      endDate,
    });
  } catch (error) {
    return { status: false, message: 'Something went wrong' };
  }
};

/** Used for getting the ID and name of all stores when changing a mobile user's
 * store */
export const getAllStores = async () => {
  try {
    return (await httpGet('store/read', `admin/get-all`)) as {
      id: string;
      name: string;
    }[];
  } catch (error) {
    handleError(error);
    return null;
  }
};

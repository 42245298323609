import { useCallback, useEffect, useState } from 'react';
import type { TablePaginationConfig } from 'antd/es/table';
import type {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
} from 'antd/es/table/interface';
import QueryString from 'qs';

import { httpGet, httpPost } from '@/services/util/axios';
import { Service } from '@/services/util/type';
import { get } from 'lodash';

type TableParams = {
  pagination?: TablePaginationConfig;
  columnKey?: string;
  order?: 'ascend' | 'descend';
  filters?: Record<string, FilterValue>;
  [key: string]: any;
};

type Props = {
  service: Service;
  serviceKind?: string;
  path?: string;
  params?: { [key: string]: any };
  perPage?: number;
  defaultSort?: { field: string; order: 'ascend' | 'descend' };
  requestType?: 'get' | 'post';
};

export const useTable = <T>({
  service,
  path = 'list',
  params = {},
  perPage = 15,
  defaultSort,
  requestType = 'get',
}: Props) => {
  const [dataSource, setDataSource] = useState<T[]>();
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: perPage,
    },
    field: get(defaultSort, 'field', ''),
    order: get(defaultSort, 'order', 'ascend'),
  });

  const getTableData = useCallback(
    async (noCache = false) => {
      setLoading(true);

      let sort: any = {};

      if (tableParams.field && tableParams.order) {
        sort = {
          field: tableParams.field,
          order: tableParams.order === 'ascend' ? 'asc' : 'desc',
        };
      }

      const filters = get(params, 'filters', {});

      let queryToBeStringified = {
        filters: {
          ...filters,
          ...tableParams.filters,
        },
        currentPage: tableParams.pagination?.current,
        perPage: tableParams.pagination?.pageSize,
        sort,
      };

      if (requestType === 'get') {
        queryToBeStringified = {
          ...params,
          ...queryToBeStringified,
        };
      }

      const query = QueryString.stringify(queryToBeStringified);

      const url = `${path}?${query}&${noCache ? 'cache=no' : ''}`;
      let items: any = [];
      let _meta: any = {};
      let data: any = {};

      if (requestType === 'post') {
        data = await httpPost(service, url, params);
      } else {
        data = await httpGet(service, url);
      }

      items = get(data, 'items', []);
      _meta = get(data, '_meta', {});

      setDataSource(items);
      setTableParams((prev) => ({
        ...prev,
        pagination: {
          ...prev.pagination,
          total: _meta.totalCount,
        },
      }));
      setLoading(false);
    },
    [
      JSON.stringify(params),
      path,
      requestType,
      service,
      tableParams.field,
      tableParams.filters,
      tableParams.order,
      tableParams.pagination?.current,
      tableParams.pagination?.pageSize,
    ],
  );

  const onChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<T> | any,
    extra: TableCurrentDataSource<T>,
  ): void => {
    setTableParams((prev) => ({
      ...prev,
      pagination,
      filters: {
        ...prev.filters,
        ...filters,
      },
      ...sorter,
    }));

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setDataSource([]);
    }
  };

  useEffect(() => {
    getTableData();
  }, [getTableData]);

  useEffect(() => {
    setTableParams((prev) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        current: 1,
      },
    }));
  }, [JSON.stringify(params)]);

  return {
    tableProps: {
      dataSource,
      pagination: tableParams.pagination,
      loading,
      onChange,
    },
    tableParams,
    refresh: () => {
      setTimeout(() => getTableData(true), 0);
    },
  };
};

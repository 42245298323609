import { FC, Key, useEffect, useState } from 'react';
import {
  Avatar,
  Badge,
  Button,
  Col,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Typography,
} from 'antd';
import { useTable } from '@/hooks';
import { Brand } from '@/types';
import { IoPlanetOutline } from 'react-icons/io5';
import { TableContainer } from '@/styles';
import { When } from 'react-if';
import { ModalFooter } from '@/components/BrandPickerModal/styles';
import { filter, isFunction } from 'lodash';

type BaseArgs = {
  onSelect: (selectedIds: string[], brands: Brand[]) => void;
  selected: string[];
  multiple?: boolean;
  onClose: () => void;
  hideInactive?: boolean;
  removeSelected?: boolean;
  onSelectBrands?: (brands: Brand[]) => void;
};

type Args = {
  open: boolean;
} & BaseArgs;

const BrandPicker: FC<BaseArgs> = ({
  selected,
  onSelect,
  onSelectBrands,
  onClose,
  multiple,
  hideInactive,
  removeSelected,
}) => {
  const [filters, setFilters] = useState({
    text: '',
    type: 'all',
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

  useEffect(() => {
    setSelectedRowKeys(selected);
  }, [selected]);

  const { tableProps, refresh } = useTable<Brand>({
    service: 'brand/read',
    path: `admin/list`,
    requestType: 'post',
    params: {
      status: 'approved',
      selected,
      filters: {
        text: filters.text,
        type: filters.type,
        hideInactive,
        removeSelected,
      },
    },
  });

  const onChangeSelection = (brandsId: string[]) => {
    const brands = filter(tableProps?.dataSource, (brand: Brand) => {
      return brandsId.includes(brand.id);
    });

    onSelect(
      selectedRowKeys.map((key) => key.toString()),
      brands,
    );

    if (onSelectBrands) {
      onSelectBrands(brands);
    }
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Row gutter={16}>
        <Col span={16}>
          <Input.Search
            placeholder="Search by brand name"
            allowClear
            onSearch={(value) => {
              setFilters((prev: any) => ({ ...prev, text: value }));
            }}
            style={{ width: '100%' }}
          />
        </Col>
        <Col span={8}>
          <Select
            style={{ width: '100%' }}
            placeholder="Showing All"
            optionFilterProp="children"
            defaultValue={'all'}
            onChange={(value) =>
              setFilters((prev: any) => ({ ...prev, type: value }))
            }
            options={[
              {
                value: 'retailer',
                label: 'Showing retailers Only',
              },
              {
                value: 'brand',
                label: 'Showing brands Only',
              },
            ]}
          />
        </Col>
      </Row>

      <TableContainer>
        <Table
          {...tableProps}
          pagination={{
            ...tableProps.pagination,
            pageSize: 10,
            showSizeChanger: false,
          }}
          rowKey={'id'}
          size={'small'}
          // @ts-ignore
          onRow={(record: Brand, index: number) => {
            return {
              onClick: (event) => {
                if (!multiple) {
                  setSelectedRowKeys([record.id]);
                  return;
                }

                if (selectedRowKeys.includes(record.id)) {
                  setSelectedRowKeys((prev) =>
                    prev.filter((key) => key !== record.id),
                  );
                } else {
                  setSelectedRowKeys([...selectedRowKeys, record.id]);
                }
              },
            };
          }}
          rowSelection={{
            selectedRowKeys,
            type: multiple ? 'checkbox' : 'radio',
            preserveSelectedRowKeys: true,
            hideSelectAll: false,
            onChange: (keys) => {
              setSelectedRowKeys(keys);
            },
          }}
        >
          <Table.Column
            title="Brand"
            dataIndex="name"
            key="name"
            sorter
            render={(text, record: Brand) => {
              return (
                <Space.Compact>
                  <Avatar
                    src={record.logoUrl}
                    style={{ marginRight: 10 }}
                    size={44}
                    shape={'square'}
                    icon={<IoPlanetOutline />}
                  />

                  <Space.Compact
                    direction="vertical"
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography.Text
                      className="name"
                      ellipsis
                      strong
                      style={{ fontSize: 16, lineHeight: '22px' }}
                    >
                      {record.name}
                    </Typography.Text>
                    {record.isManaged ? (
                      <Tag
                        color="#ff8000"
                        style={{
                          fontSize: 10,
                          fontWeight: 300,
                          padding: '0 4px',
                          borderRadius: 4,
                        }}
                      >
                        Retailer
                      </Tag>
                    ) : (
                      <Tag
                        color="purple"
                        style={{
                          fontSize: 10,
                          fontWeight: 300,
                          padding: '0 4px',
                          borderRadius: 4,
                        }}
                      >
                        Brand
                      </Tag>
                    )}
                  </Space.Compact>
                </Space.Compact>
              );
            }}
          />
        </Table>
      </TableContainer>

      <ModalFooter>
        <div />
        <div className="actions">
          <Button
            onClick={() => {
              setSelectedRowKeys([]);
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => {
              onChangeSelection(selectedRowKeys.map((key) => key.toString()));
              onClose();
            }}
          >
            Confirm
          </Button>
        </div>
      </ModalFooter>
    </Space>
  );
};

const BrandPickerModal: FC<Args> = ({
  open,
  onClose,
  onSelect,
  selected,
  multiple = false,
  hideInactive = false,
  removeSelected = false,
  onSelectBrands,
}) => {
  return (
    <Modal
      title={multiple ? 'Select Brands' : 'Select Brand'}
      open={open}
      onCancel={onClose}
      centered
      footer={null}
    >
      <When condition={open}>
        <BrandPicker
          selected={selected}
          onSelect={onSelect}
          multiple={multiple}
          onClose={onClose}
          hideInactive={hideInactive}
          removeSelected={removeSelected}
          onSelectBrands={onSelectBrands}
        />
      </When>
    </Modal>
  );
};

export default BrandPickerModal;

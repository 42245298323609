import styled from 'styled-components';

export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;

  .actions {
    display: flex;
    column-gap: 8px;
  }
`;

import styled from 'styled-components';
import { Descriptions } from 'antd';

export const FormContainer = styled.div`
  #search-store {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;

    &:focus {
      border-color: #6f56bc;
      box-shadow: 0 0 0 2px rgba(117, 30, 217, 0.08);
      outline: 0;
    }
  }
`;

export const StoreDescription = styled(Descriptions)`
  .ant-descriptions-view {
    table {
      tbody {
        tr {
          .ant-descriptions-item {
            padding-bottom: 0;
          }

          &:nth-child(even) {
            .ant-descriptions-item {
              padding-bottom: 16px;
            }
          }
        }
      }
    }
  }
`;

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import ProtectedRoute from '@/components/Security/ProtectedRoute';
import AdminUserListPage from '@/pages/AdminUser/List';
import WebUserList from '@/pages/Brand/WebUserList';
import CashoutRequest from '@/pages/CashOutRequest';
import DashboardPage from '@/pages/Dashboard';
import DevQueueListPage from '@/pages/dev/queue/List';
import DevQueueListDetailsPage from '@/pages/dev/queue/ListDetails';
import GeneralError from '@/pages/Errors/GeneralError';
import NotAuthorizedPage from '@/pages/Errors/NotAuthorized';
import NotFoundPage from '@/pages/Errors/NotFound';
import Login from '@/pages/Login';
import MissionApproval from '@/pages/MissionApproval';
import UsersPoeList from '@/pages/MobileUser/UsersPOEStatus';
import ReportKafkaDlqPage from '@/pages/ReportKafkaDlqPage';
import StoreEdit from '@/pages/Store/Edit';
import StoreList from '@/pages/Store/List';
import AdminUserEdit from './pages/AdminUser/Edit';
import BrandCreate from './pages/Brand/Create';
import BrandEdit from './pages/Brand/Edit';
import BrandList from './pages/Brand/List';
import BrandManage from './pages/Brand/Manage';
import WebUserManage from './pages/Brand/WebUserManage';
import CampaignList from './pages/Campaign/List';
import CompanyAdd from './pages/Company/Add';
import CompanyDetail from './pages/Company/Detail';
import CompanyEdit from './pages/Company/Edit';
import CompanyList from './pages/Company/List';
import CompanyMigration from './pages/Company/Migration';
import JobPage from './pages/dev/queue/Job';
import DevQueueJobsPage from './pages/dev/queue/Job/list-page';
import IntegrationConnectorEdit from './pages/Integration/connector/Edit';
import IntegrationConnectorList from './pages/Integration/connector/List';
import MapDashboardPage from './pages/MapDashboard';
import MobileUserList from './pages/MobileUser/List';
import MobileUserManage from './pages/MobileUser/Manage';
import { Report } from './pages/Report';
import StoreManage from './pages/Store/Manage';
import DealerImport from '@/pages/DealerImport/List';
import DealerImportManage from '@/pages/DealerImport/Manage';

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* not required auth pages */}
        <Route path="/login" element={<Login />} />
        {/* required auth and permissions pages */}
        <Route
          index
          path="/"
          element={
            <ProtectedRoute allowedPermissions={['*']}>
              <DashboardPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute allowedPermissions={['*']}>
              <DashboardPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-user"
          element={
            <ProtectedRoute allowedPermissions={['admin_user.view']}>
              <AdminUserListPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/store"
          element={
            <ProtectedRoute allowedPermissions={['stores.view']}>
              <StoreList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/campaign"
          element={
            <ProtectedRoute allowedPermissions={['campaigns.view']}>
              <CampaignList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/store/:storeId/manage"
          element={
            <ProtectedRoute allowedPermissions={['stores.view']}>
              <StoreManage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/store/:storeId/edit"
          element={
            <ProtectedRoute allowedPermissions={['stores.edit']}>
              <StoreEdit />
            </ProtectedRoute>
          }
        />
        <Route
          path="/mobile-user"
          element={
            <ProtectedRoute allowedPermissions={['users.view']}>
              <MobileUserList />
            </ProtectedRoute>
          }
        />
        {/*<Route*/}
        {/*  path="/mobile-user/pending-store-requests"*/}
        {/*  element={*/}
        {/*    <ProtectedRoute allowedPermissions={['users.edit']}>*/}
        {/*      <PendingStoreRequest />*/}
        {/*    </ProtectedRoute>*/}
        {/*  }*/}
        {/*/>*/}
        <Route
          path="/mobile-user/:id/manage"
          element={
            <ProtectedRoute allowedPermissions={['users.view']}>
              <MobileUserManage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-user/:id/edit"
          element={
            <ProtectedRoute allowedPermissions={['admin_user.edit']}>
              <AdminUserEdit />
            </ProtectedRoute>
          }
        />

        <Route
          path="/integration/connector/list"
          element={
            <ProtectedRoute allowedPermissions={['integration.view']}>
              <IntegrationConnectorList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/integration/connector/:id/edit"
          element={
            <ProtectedRoute allowedPermissions={['integration.edit']}>
              <IntegrationConnectorEdit />
            </ProtectedRoute>
          }
        />
        <Route
          path="/messaging-controller"
          element={
            <ProtectedRoute allowedPermissions={['messaging_controler.view']}>
              <ReportKafkaDlqPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/403"
          element={
            <ProtectedRoute allowedPermissions={['*']}>
              <NotAuthorizedPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/404"
          element={
            <ProtectedRoute allowedPermissions={['*']}>
              <NotFoundPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/500"
          element={
            <ProtectedRoute allowedPermissions={['*']}>
              <GeneralError />
            </ProtectedRoute>
          }
        />
        <Route
          path="/*"
          element={
            <ProtectedRoute allowedPermissions={['*']}>
              <NotFoundPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/map"
          element={
            <ProtectedRoute allowedPermissions={['report.view']}>
              <MapDashboardPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dev/queue/lists"
          element={
            <ProtectedRoute allowedPermissions={['queue.view']}>
              <DevQueueListPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dev/queue/jobs"
          element={
            <ProtectedRoute allowedPermissions={['queue.view']}>
              <DevQueueJobsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dev/queue/:id"
          element={
            <ProtectedRoute allowedPermissions={['queue.view']}>
              <DevQueueListDetailsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dev/queue/:id/job/:jobId"
          element={
            <ProtectedRoute allowedPermissions={['queue.view']}>
              <JobPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/company"
          element={
            <ProtectedRoute allowedPermissions={['companies.view']}>
              <CompanyList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/company/create"
          element={
            <ProtectedRoute allowedPermissions={['companies.edit']}>
              <CompanyAdd />
            </ProtectedRoute>
          }
        />
        <Route
          path="/company/:id/edit"
          element={
            <ProtectedRoute allowedPermissions={['companies.edit']}>
              <CompanyEdit />
            </ProtectedRoute>
          }
        />
        <Route
          path="/company/:id/detail"
          element={
            <ProtectedRoute allowedPermissions={['companies.view']}>
              <CompanyDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/company/:id/migration"
          element={
            <ProtectedRoute allowedPermissions={['companies.edit']}>
              <CompanyMigration />
            </ProtectedRoute>
          }
        />
        <Route
          path="/brand"
          element={
            <ProtectedRoute allowedPermissions={['brands.view']}>
              <BrandList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/brand/web-users"
          element={
            <ProtectedRoute allowedPermissions={['web_user.view']}>
              <WebUserList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/brand/:brandId/manage"
          element={
            <ProtectedRoute allowedPermissions={['brands.view']}>
              <BrandManage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/brand/create"
          element={
            <ProtectedRoute allowedPermissions={['brands.edit']}>
              <BrandCreate />
            </ProtectedRoute>
          }
        />
        <Route
          path="/brand/:brandId/edit"
          element={
            <ProtectedRoute allowedPermissions={['brands.edit']}>
              <BrandEdit />
            </ProtectedRoute>
          }
        />
        <Route
          path="/mission-approval"
          element={
            <ProtectedRoute
              allowedPermissions={[
                'consumer_completion.view',
                'sales_incentive_completion.view',
              ]}
            >
              <MissionApproval />
            </ProtectedRoute>
          }
        />

        <Route
          path="/cashout-request"
          element={
            <ProtectedRoute allowedPermissions={['cashout_request.view']}>
              <CashoutRequest />
            </ProtectedRoute>
          }
        />
        <Route
          path="/users-poe-list"
          element={
            <ProtectedRoute allowedPermissions={['users.edit']}>
              <UsersPoeList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/brand/web-user/:id/manage"
          element={
            <ProtectedRoute allowedPermissions={['web_user.view']}>
              <WebUserManage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dealer-import"
          element={
            <ProtectedRoute allowedPermissions={['import.view']}>
              <DealerImport />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dealer-import/:id/manage"
          element={
            <ProtectedRoute allowedPermissions={['import.view']}>
              <DealerImportManage />
            </ProtectedRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;

import { handleError, httpGet, httpPut, httpPost } from './util/axios';

export const getBrandDetails = async (id: string) => {
  try {
    return httpGet('brand/read', `admin/details?brandId=${id}`);
  } catch (error: any) {
    handleError(error);
  }
};

export const getFormData = async (id: string) => {
  try {
    return httpGet('brand/read', `admin/form-data?brandId=${id}`);
  } catch (error: any) {
    handleError(error);
  }
};

export const saveBrandWalletAjustment = async ({
  brandId,
  amount,
  operationType,
  annotations,
}: any) => {
  try {
    return httpPost('brand/write', 'admin/wallet/adjustment', {
      brandId,
      amount,
      operationType,
      annotations,
    });
  } catch (error: any) {
    handleError(error);
  }
};

export const getIndustries = async () => {
  try {
    return httpGet('brand/read', 'admin/industries');
  } catch (error: any) {
    handleError(error);
  }
};

export const updateBrand = async (brandId: string, args: any) => {
  try {
    return (await httpPut('brand/write', `admin/${brandId}/update`, args)) as
      | { status: true }
      | { status: false; message: string };
  } catch (error: any) {
    handleError(error);
  }
};

export const inactiveBrandUsers = async (brandId: string) => {
  try {
    return httpPut('brand/write', `admin/${brandId}/inactive-users`);
  } catch (error: any) {
    handleError(error);
  }
};

export const createBrand = async (args: any) => {
  try {
    return (await httpPost('brand/write', 'admin/create', args)) as
      | { status: true; brandId: string }
      | { status: false; message: string };
  } catch (error: any) {
    handleError(error);
  }
};

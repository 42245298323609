import { useAudit, useTable } from '@/hooks';
import { TableContainer } from '@/styles';
import { Button, Table, Typography, Form, Input, Space, Avatar } from 'antd';
import { formatISODate } from '@/utils';
import { PlusOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useSession } from '@/store';
import { SiStackedit } from 'react-icons/si';

import { ButtonLink } from '@/components/ButtonLink';

type TableData = {
  id: string;
  name: string;
  logoUrl: string;
  status: string;
  connectedAt: string;
  storeBrandId: string;
  totalLiveMission: {
    contest: number;
    general: number;
    learning: number;
  };
};

type Params = {
  storeId: string;
  onRefresh: () => void;
};

const BrandsTab = ({ storeId, onRefresh }: Params) => {
  const audit = useAudit({
    resourceName: 'brand-members-tab',
  });

  useEffect(() => {
    audit.onAccess();
  }, []);

  const session = useSession();
  const [form] = Form.useForm();
  const [filter, setFilter] = useState('');
  const [status, setStatus] = useState<'approved' | 'pending' | 'rejected'>(
    'approved',
  );
  const { tableProps, refresh } = useTable<TableData>({
    service: 'store/read',
    path: `admin/${storeId}/brand-list`,
    params: {
      status,
      filters: {
        text: filter,
      },
    },
    defaultSort: {
      field: 'brandName',
      order: 'ascend',
    },
  });

  return (
    <div>
      <TableContainer>
        <Table
          {...tableProps}
          rowKey={'id'}
          title={() => (
            <div className="table-header">
              <Space className="title">
                <Button
                  onClick={() => {
                    setStatus('approved');
                    refresh();
                  }}
                  type={status === 'approved' ? 'primary' : 'default'}
                >
                  Approved
                </Button>
                <Button
                  onClick={() => {
                    setStatus('pending');
                    refresh();
                  }}
                  type={status === 'pending' ? 'primary' : 'default'}
                >
                  Pending
                </Button>
                <Button
                  onClick={() => {
                    setStatus('rejected');
                    refresh();
                  }}
                  type={status === 'rejected' ? 'primary' : 'default'}
                >
                  Rejected
                </Button>
              </Space>
              <div className="actions">
                <Space>
                  <Input.Search
                    placeholder="Search by name or email"
                    allowClear
                    onSearch={(value) => {
                      setFilter(value);
                    }}
                    style={{ width: 260 }}
                  />
                  <Button type="primary" icon={<PlusOutlined />} disabled>
                    Invite Member
                  </Button>
                </Space>
              </div>
            </div>
          )}
        >
          <Table.Column
            title="Brand Name"
            dataIndex="brandName"
            key="name"
            sorter
            fixed="left"
            render={(text, record: TableData) => (
              <Typography.Text strong>{record.name}</Typography.Text>
            )}
          />

          <Table.Column
            dataIndex={'totalLiveMission.contest'}
            title="Total Live Contest"
            sorter
            align="left"
            render={(text, record: TableData) => (
              <Space.Compact direction={'vertical'}>
                <Typography.Text>
                  <strong>Contest: </strong> {record.totalLiveMission.contest}
                </Typography.Text>
                <Typography.Text>
                  <strong>General: </strong> {record.totalLiveMission.general}
                </Typography.Text>
                <Typography.Text>
                  <strong>Learning: </strong> {record.totalLiveMission.learning}
                </Typography.Text>
              </Space.Compact>
            )}
          />

          <Table.Column
            title="Connected At"
            dataIndex="connectedAt"
            sorter
            defaultSortOrder={'descend'}
            align="center"
            render={(text) => {
              return (
                <Space.Compact
                  direction={'vertical'}
                  className="transaction-column-date"
                >
                  <strong>{formatISODate(text, 'DD')}</strong>
                  <small>{formatISODate(text, 't')}</small>
                </Space.Compact>
              );
            }}
          />

          <Table.Column
            dataIndex={'actions'}
            align={'right'}
            render={(text, record: TableData) => (
              <Space>
                <ButtonLink
                  to={`/brand/${record.id}/manage`}
                  icon={<SiStackedit size={20} />}
                  style={{ alignItems: 'center', display: 'flex' }}
                >
                  Manage
                </ButtonLink>
              </Space>
            )}
          />
        </Table>
      </TableContainer>
    </div>
  );
};

export default BrandsTab;

import { useTable } from "@/hooks";
import { Store } from "@/types";
import { Avatar, Button, Input, Space, Table, Typography } from "antd";
import { Key, useEffect, useState } from "react";
import { IoPlanetOutline } from "react-icons/io5";
import { ModalFooter, PickerContainer, TableContainer } from "./styles";
import { set } from "lodash";

type Props = {
  selected: string[];
  multiple?: boolean;
  brandId?: string;
  onClose: () => void;
  onConfirm: (selectedIds: string[]) => void;
};

const StorePicker = ({ selected, onClose, onConfirm, brandId }: Props) => {
  const [filter, setFilter] = useState('');
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

  useEffect(() => {
    setSelectedRowKeys(selected);
  }, [selected]);

  const { tableProps, refresh } = useTable<Store>({
    service: 'store/read',
    path: `admin/list`,
    params: {
      status: 'approved',
      collection: 'stores',
      selected,
      filters: {
        text: filter,
        brandId: brandId,
      },
    },
  });

  const handleOnConfirm = () => {
    onConfirm(selectedRowKeys.map((key) => key.toString()));
    setSelectedRowKeys([]);
  }

  return (
    <PickerContainer>
      <Input.Search
        placeholder="Search by name"
        allowClear
        onSearch={(value) => {
          setFilter(value);
        }}
        style={{ width: '100%', marginBottom: 24 }}
      />
      <TableContainer>
        <Table
          {...tableProps}
          pagination={{
            ...tableProps.pagination,
            pageSize: 5,
            showSizeChanger: false,
          }}
          rowKey={'id'}
          size={'small'}
          // @ts-ignore
          onRow={(record: Store, index: number) => {
            return {
              onClick: (event) => {
                if (selectedRowKeys.includes(record.id)) {
                  setSelectedRowKeys((prev) =>
                    prev.filter((key) => key !== record.id),
                  );
                } else {
                  setSelectedRowKeys([...selectedRowKeys, record.id]);
                }
              },
            };
          }}
          rowSelection={{
            selectedRowKeys,
            type: 'checkbox',
            preserveSelectedRowKeys: true,
            hideSelectAll: false,
            onChange: (keys) => {
              setSelectedRowKeys(keys);
            },
          }}
        >
          <Table.Column
            title="Stores"
            dataIndex="name"
            key="name"
            sorter
            render={(text, record: Store) => {
              return (
                <Space.Compact>
                  <Avatar
                    src={record.logoUrl}
                    style={{ marginRight: 10 }}
                    size={44}
                    shape={'square'}
                    icon={<IoPlanetOutline />}
                  />

                  <Space.Compact
                    direction="vertical"
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography.Text
                      className="name"
                      ellipsis
                      strong
                      style={{ fontSize: 16, lineHeight: '22px' }}
                    >
                      {record.name}
                    </Typography.Text>
                  </Space.Compact>
                </Space.Compact>
              );
            }}
          />
        </Table>
      </TableContainer>
      <ModalFooter>
        <span>{selectedRowKeys.length} selected</span>
        <div className="actions">
          <Button
            onClick={() => {
              setSelectedRowKeys([]);
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={handleOnConfirm}
          >
            Confirm
          </Button>
        </div>
      </ModalFooter>
    </PickerContainer >
  )
}

export default StorePicker;